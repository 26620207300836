<script>
import ResourceFrame from "../components/ResourceFrame.vue";
import DynamicTitle from "../../common/components/DynamicTitle.vue";
import aboutHeroImage from "../../../../public/img/resources/about-hero.png";
import { SuawInputGroup, SuawParagraph, SuawHeading, SuawThumbnail, SuawButton } from "@/components";
export default {
  name: "About",
  components: { ResourceFrame, DynamicTitle, SuawInputGroup, SuawParagraph, SuawHeading, SuawThumbnail, SuawButton },
  data() {
    return {
      aboutHeroImage
    };
  },
  methods: {
    onFindAnEvent() {
      this.$router.push({
        name: "DashboardEvents"
      });
    }
  }
};
</script>

<template>
  <ResourceFrame>
    <SuawInputGroup direction="column" group-gap="quadruple" prevent-flex-grow>
      <DynamicTitle :before="['Welcome', 'To']" size="large" />
      <SuawHeading
        class="suaw-about-intro"
        level="3"
        content="Welcome to a vibrant, global community of writers who believe in the magic of writing together. Since 2007, we’ve been helping individuals around the world create spaces—both online and in-person—where people can come together to write, share, and inspire one another."
      />
      <SuawThumbnail class="suaw-about-thumbnail" :content="aboutHeroImage" />
      <h2 class="suaw-about-subtitle">
        Our Mission
      </h2>
      <SuawParagraph
        text="Shut Up & Write!’s mission is to provide everyone with the resources, tools, and methodology to create inclusive and supportive writing groups. The organization believes that writing is for everyone and that fostering a culture of community, accountability, and encouragement helps individuals develop sustainable writing habits."
      />
      <SuawParagraph
        text="By offering a simple yet effective framework, Shut Up & Write! empowers people around the world to start and sustain writing groups, making the act of writing more accessible, enjoyable, and impactful. Through its global network, Shut Up & Write! ensures that anyone, anywhere, can build a supportive writing community that enhances productivity and well-being."
      />
      <h2 class="suaw-about-subtitle">
        Our Community-Led Events
      </h2>
      <p class="suaw-paragraph">
        Events created within our community are free and open to anyone who wants to write. Using our simple <router-link :to="{ name: 'Method' }">methodology</router-link>,
        each event has a straightforward structure: introductions, focused writing time, and a concluding check-in. During these events, we never share our work or critique
        others; we just get our writing done. Our events are hosted by organizers from our global network, so you’ll find gatherings happening in local cafés or online, no
        matter where you are.
      </p>
      <div>
        <SuawButton class="suaw-about-button" size="large" button-text="Find an Event" @click="onFindAnEvent" />
      </div>
    </SuawInputGroup>
  </ResourceFrame>
</template>

<style lang="scss">
.suaw-paragraph {
  color: var(--sem-color-text-medium);
  line-height: var(--p-line-height);
  font-size: var(--p-font-size);
  font-weight: var(--p-font-weight);
}
.suaw-about-intro {
  color: var(--sem-color-neutral-dark);
}
.suaw-about-subtitle {
  color: #414141;
  font-family: Archer;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}
.suaw-about-thumbnail {
  aspect-ratio: 2;
  @media (max-width: 650px) {
    aspect-ratio: 1.5;
  }
}
.suaw-about-button {
  @media (max-width: 650px) {
    width: 100%;
  }
}
</style>
